export const humanizeCount = (count: number, singular: string, plural: string) => {
  switch (count) {
    case 0:
      return `No ${plural}`;
    case 1:
      return `1 ${singular}`;
    default:
      return `${count} ${plural}`;
  }
};

export const exportCsv = (fileName: string, csv) => {

  const anchor = document.createElement('a');
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  anchor.target = '_blank';
  anchor.download = `${fileName}.csv`;
  anchor.click();
};

export const removeHTMLTags = (html: string) => {
  return html?.replace(/<[^>]*>?/gm, '');
};

export const constructOrderLink = (storeDomain: string, orderId: string, platformId: string) => {
  switch (platformId) {
    case 'shopify':
      return `https://${storeDomain}/admin/orders/${orderId}`;
    case 'bigcommerce':
      return `https://store-${storeDomain}/manage/orders/${orderId}`;
    default:
      return '';
  }
};

export const isShopify = platformId => {
  return platformId === 'shopify';
};

export const isBigcommerce = platformId => {
  return platformId === 'bigcommerce';
};

function toNumOrNull(value: string | number | null) {
  if (value === undefined || value === null) {
    return null;
  }

  return Number(value);
}

export function formatLongNumber(number: number) {
  return number.toLocaleString('en-US', {
    maximumFractionDigits: 2
})
}

export function transformPriceRules(rules: any[] = []) {
  return rules.map(rule => {
    return {
      brandCalcMethod: rule.terms.brand?.type || null,
      brandPrice: toNumOrNull(rule.terms.brand?.amount),
      customerCalcMethod: rule.terms.customer?.type || null,
      customerPrice: toNumOrNull(rule.terms.customer?.amount) || null,
      hybridOperator: rule.terms.operator?.toUpperCase() || null,
      id: rule.id || undefined,
      maxValue: parseInt(rule.max), // these have to be defined so no null
      minValue: parseInt(rule.min),
    };
  });
}

export const typedArrayToBuffer = (array: Uint8Array): ArrayBuffer => {
  return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
};
